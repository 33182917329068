import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import '../css/style.css'
import { useData } from "../components/DataProvider/DataProvider";
import Input from "../components/Input/Input";
import ITA from '../images/italy.png'
import ENG from '../images/united-kingdom.png'

const { v4: uuid } = require('uuid')

function initialize(x, id) {
    if (x === undefined)
        return {
            "id": uuid(),
            "highlight": false
        }
    return x
}

export default function EventEditor() {

    const params = useParams()
    const navigate = useNavigate()
    const { data, removeItem, updateItem, changeOrder, hideCategory, updateEvent, removeEvent } = useData()
    const [item, setItem] = useState(initialize(data.events[params.id], params.id))
    const [message, setMessage] = useState(null)

    const template = {
        "name": "text",
        "logo": "text",
        "locandina": "text",
        "descrizione": "longtext",
        "data": "text"
    }

    async function save() {
        try {
            updateEvent(item)
            setMessage("Saved correctly!")
        } catch (e) {
            setMessage(e)
        }
        setTimeout(() => setMessage(null), 3500)
    }

    function setValue(key, value) {
        var new_item = { ...item }
        new_item[key] = value
        setItem(new_item)
    }

    function createInputItem(key, inputTemplate) {
        return <div key={key} className="row mb-3">
            <div className="col-12 col-md-6 label text-uppercase">
                <Input label={key} flag={ITA} value={item[key]} type={inputTemplate} id={key} onChange={(e) => setValue(key, e.target.value)} />
            </div>
            <div className="col-12 col-md-6 label text-uppercase">
                <Input label={key} flag={ENG} value={item[key + "_eng"]} type={inputTemplate} id={key + "_eng"} onChange={(e) => setValue(key + "_eng", e.target.value)} />
            </div>
        </div>
    }

    function createTemplate() {
        const ignore = []

        return <div className="row">
            <div className="container-fluid">
                {Object.keys(template).map(key => {
                    if (!(ignore.includes(key)))
                        return createInputItem(key, template[key])
                })}
            </div>
        </div>
    }

    return (
        <div className="container-fluid h-100 w-100 p-3 p-md-5 can-scroll">

            {/* Message window */}
            <div className={message != null ? "clem-message d-flex align-items-center justify-content-center" : "d-none"}>{message}</div>

            {/* ITEM NAME */}
            {item !== null && <div className="row mt-5 mb-3 title text-clem">
                <div className="col-12 text-center text-md-left">
                    {(!("name" in item) || item.name.length === 0) ? "Create new event" : item.name}
                </div>
            </div>}

            <div className="row justify-content-center mb-3">
                <div className="col-auto">
                    <button onClick={() => navigate(-1)} >Back &#128072;&#127995;</button>
                </div>
                <div className="col-auto">
                    <button onClick={() => save()} >Salva &#128190;</button>
                </div>
            </div>

            {createTemplate()}
        </div>
    );

}