import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import '../css/style.css'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useData } from "../components/DataProvider/DataProvider";
import Price from "../components/Price/Price";

var grid = 8

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {

  // Convert the result into an Array
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  result.forEach((item, index) => {
    item.position = index
  })

  return result;
};

/**
 * Sorting function for the items array
 */
const sortItems = (list) => {
  const result = Array.from(list);
  result.sort((a, b) => a.position > b.position ? 1 : -1);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "grey",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({

});

export default function Products() {

  const params = useParams()
  const { data, removeItem, updateItem, changeOrder } = useData()
  const navigate = useNavigate()
  const [items, setItems] = useState([])
  const [showGrid, setShowGrid] = useState({})
  const [currentTemplate, setCurrentTemplate] = useState(null)
  const [wait, setWait] = useState(false)

  useEffect(() => {

    if (currentTemplate == null)
      setCurrentTemplate(params.id in data.templates.v1["beverage"] ? data.templates.v1["beverage"][params.id] : data.templates.v1["food"][params.id])

    prepareItems()
  }, [data])

  async function prepareItems() {
    let it = []

    // Transform the dictionary into an array
    Object.values(data.items).map((item, ix) => {
      if (item.class === params.id)
        it.push(item)
    })
    
    // Sort items accordigly to the position in the payload
    it = sortItems(it)

    setItems(it)
  }

  /**
   * Change the visibility of the selected item.
   */
  function toggleVisibility(id) {
    var newShowGrid = { ...showGrid }

    // Change the visibility flag
    newShowGrid[id] = (!(id in newShowGrid)) ? true : newShowGrid[id] = !newShowGrid[id]
    setShowGrid(newShowGrid)
  }

  function onDragEnd(result) {
    if (!result.destination)
      return;

    setWait(true)
    changeOrder(params.id, items[result.source.index]["id"], result.destination.index, () => {
      setWait(false)
    })
  }

  async function toggleMenuVisibility(item) {
    item['is_hidden'] = !item['is_hidden']
    updateItem("items", item)
  }

  async function toggleNewFlag(item) {
    item['is_new'] = !item['is_new']
    updateItem("items", item)
  }

  function createItemStyle(item) {
    var ignore = ["name", "prices", "sezione", "image", "id", "class", "position", "image_url", "is_new", "is_hidden", "class_readable"]

    return <div className="row item-card p-2 p-md-3">
      <div className="col-12 col-md-1 text-center d-none d-md-block d-flex align-items-center text-clem index">
        {item.position}
      </div>

      <div className="col-12 col-md-10">
        <div className="row">

          {/* NOME */}
          <div className="col-12 title text-clem">
            {item.name}
          </div>

          <div className="row mt-2 mb-2">

            {/* INDICATORE NOVITA' */}
            <div className="col-12 col-md-3 flex-column d-flex mb-3 mb-md-0">
              <div className="label">
                Indicatore novità
              </div>
              {item['is_new'] == true ? (<div className="text-success content-bold">ATTIVO</div>) : (<div className="text-warning content-bold">DISATTIVO</div>)}
            </div>

            {/* NASCOSTO DAL MENU */}
            <div className="col-12 col-md-3 flex-column d-flex mb-3 mb-md-2">
              <div className="label">
                Stato
              </div>
              {item['is_hidden'] == false || !("is_hidden" in item) ? (<div className="text-success content-bold">VISIBILE</div>) : (<div className="text-warning content-bold">NASCOSTO</div>)}
            </div>

            {/* SEZIONE ITA */}
            <div className="col-12 col-md-3 flex-column d-flex mb-3 mb-md-2">
              <div className="label">
                Sezione (ITA)
              </div>
              <div className="content-bold">{item['sezione']}</div>
            </div>

            {/* SEZIONE ENG */}
            <div className="col-12 col-md-3 flex-column d-flex mb-3 mb-md-2">
              <div className="label">
                Sezione (ENG)
              </div>
              <div className="content-bold">{item['sezione_eng']}</div>
            </div>

          </div>

          <hr ></hr>

          {/* PRICES */}
          <div className="col-12 mb-3">
            <div className="row">
              <Price prices={item['prices']}></Price>
            </div>
          </div>

          <hr ></hr>

          {/* ACTION BUTTON */}
          <div className="col-12 mb-3">
            <div className="label mb-2">
              Azioni
            </div>
            <button onClick={() => removeItem(item.id)} className="me-md-2 me-0 col-12 col-md-auto mb-3 mb-md-2">Delete &#10060;</button>
            <button onClick={() => navigate('/products/edit/' + item.id)} className="me-md-2 me-0 col-12 col-md-auto mb-3 mb-md-2">Edit &#9997;</button>
            <button onClick={() => toggleNewFlag(item)} className="me-md-2 me-0 col-12 col-md-auto mb-3 mb-md-2">Highlight &#9889;</button>
            <button onClick={() => toggleMenuVisibility(item)} className="me-md-2 me-0 col-12 col-md-auto mb-3 mb-md-2">{(item.is_hidden == false) || !('is_hidden' in item) ? "Hide" : "Show"} &#128584;</button>
            <button onClick={() => toggleVisibility(item.id)} className="me-md-2 me-0 col-12 col-md-auto mb-3 mb-md-2">{!(item.id in showGrid) || (item.id in showGrid && showGrid[item.id] == false) ? "Show more" : "Hide more"} &#128527;</button>
          </div>

          <hr ></hr>

          {/* MORE INFO */}
          {currentTemplate != null && Object.keys(currentTemplate["info"]).map((key, index) =>
            !(ignore.includes(key)) && (
              <div key={key} id={item.id} className={!(item.id in showGrid) || (item.id in showGrid && showGrid[item.id] == false) ? 'col-12 mt-2 d-none' : 'col-12 mt-2 d-block'}>
                <div className="row">
                  <div className="col-6 label">{key + " (ITA)"}</div>
                  <div className="col-6 label">{key + " (ENG)"}</div>
                  <div className="col-6">{item[key]}</div>
                  <div className="col-6">{item[key + '_eng']}</div>
                </div>
              </div>
            )
          )}

        </div>
      </div>
      <div className="col-1">
        <img className="w-100" src={item.image_url} />
      </div>
    </div>
  }

  return <div className="container-fluid h-100 w-100 p-3 p-md-5 can-scroll">
      {wait === true && <div className="wait-popup d-flex justify-content-center align-items-center">
        Aspetta un secondo, sto salvando l'ordine dei tuoi prodotti...
      </div>}
      <div className="row mt-5 mb-3 title text-clem">
        <div className="col-12 text-center text-md-left">
          {params.id}
        </div>
      </div>

      <div className="row mb-5 justify-content-center">
        <div className="col-12 col-md-auto mb-2 mb-md-0 text-center">
          <button onClick={() => navigate(-1)} >Back &#128072;&#127995;</button>
        </div>
        <div className="col-12 col-md-auto text-center">
          <button onClick={() => navigate('/products/edit/' + params.id)}>Add a new product</button>
        </div>
      </div>

      {items !== null && <div className="row p-0 m-0">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        className="col-12 item-card-override"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        {createItemStyle(item)}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>}
    </div>

}