import React, { useState } from "react";
import '../css/style.css'
import { getFirestore, getDocs, collection, where, query } from "firebase/firestore";
import md5 from "md5";


export default function Login(props) {

    const db = getFirestore();
    const [username, setUsername] = useState(null)
    const [password, setPassword] = useState(null)

    async function login() {
        try {
            let data = []
            const querySnapshot = await getDocs(query(collection(db, "users"), where("username", "==", username), where("password", "==", password)))
            querySnapshot.forEach((doc) => {
                data.push(doc.data())
            });

            if (data.length > 0) {
                localStorage.setItem("clem-admin", "1")
                window.location.href = "/"
            }
        } catch (e) {
            window.location.reload()
        }
    }

    return (
        <div id="login">
            <div className="overlay h-100 w-100">
                <div className="container-fluid w-100 h-100 d-flex align-items-center justify-content-center">
                    <div className="form col-sm-4 col-12 text-center" id="login-form">
                        <div className="form-group">
                            <label className="text-white">Username</label>
                            <input type="text" name="username" id="username" className="text-center form-control" onChange={(e) => { setUsername(e.target.value) }} />
                        </div>
                        <div className="form-group">
                            <label className="text-white">Password</label>
                            <input type="password" name="password" id="password" className="text-center form-control" onChange={(e) => { setPassword(md5(e.target.value)) }} />
                        </div>
                        <div className="form-group">
                            <button onClick={() => login()} id="button" className="btn btn-danger text-center text-white w-100 mt-3">ACCEDI</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}