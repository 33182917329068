import { getFirestore } from "firebase/firestore";
import { createContext, useContext, useEffect, useState } from "react"
import { call } from "./api";

// Initialize the context
const DataContext = createContext(null);

// Create Data context provider
export function DataContextProvider({ children }) {

    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)
    const db = getFirestore()

    useEffect(() => {

        if (data === null && loading === true)
            download()

        if (data !== null)
            setLoading(false)

    }, [data, loading])

    async function removeItem(item_id) {
        let res = await call("delete_item", { "id": item_id }, () => console.log("Item deleted correctly!"), () => console.log("Failed to delete items!"))

        let new_data = { ...data }
        let tmp = {}
        Object.keys(new_data["items"]).map((id, i) => {
            if (id !== item_id)
                tmp[id] = new_data["items"][id]
        })
        new_data["items"] = tmp
        setData(new_data)
    }

    async function removeEvent(item_id) {
        let res = await call("delete_event", { "id": item_id }, () => console.log("Event deleted correctly!"), () => console.log("Failed to delete event!"))

        let new_data = { ...data }
        let tmp = {}
        Object.keys(new_data["events"]).map((id, i) => {
            if (id !== item_id)
                tmp[id] = new_data["events"][id]
        })
        new_data["events"] = tmp
        setData(new_data)
    }

    async function updateItem(coll, item) {
        let res = await call("add_item", item, () => console.log("Item added/updated correctly!"), () => console.log("Failed to add/update items!"))

        let new_data = { ...data }
        new_data[coll][item.id] = item
        setData(new_data)
    }

    async function updateEvent(item) {
        let res = await call("add_event", item, () => console.log("Item added/updated correctly!"), () => console.log("Failed to add/update items!"))

        let new_data = { ...data }
        new_data["events"][item.id] = item
        setData(new_data)
    }

    async function changeOrder(category, item_id, newPos, callback) {
        let payload = {
            "class": category,
            "id": item_id,
            "new_position": newPos
        }
        let res = await call("change_order_item", payload, () => console.log("Item updated correctly!"), () => console.log("Failed to update items!"))

        let new_data = { ...data }
        Object.keys(res).map((key, ix) => {
            new_data["items"][res[key].id] = res[key]
        })
        setData(new_data)
        callback()
    }

    async function hideCategory(macro, category) {
        let payload = {
            "class": category,
            "macro_category": macro
        }

        let res = await call("hide_category", payload, () => console.log("Category hidden!"), () => console.error("Failed to change the category status!"))

        let new_data = { ...data }
        new_data['templates'] = res
        setData(new_data)
    }

    async function download() {

        let new_data = data === null ? {} : { ...data }

        // DOWNLOAD TEMPLATES
        new_data['templates'] = await call("get_templates", {}, () => console.log("Items downloaded!"), () => console.log("Failed to download items!"))

        // DOWNLOAD ITEMS
        new_data['items'] = await call("get_items", {}, () => console.log("Items downloaded!"), () => console.log("Failed to download items!"))

        // DOWNLOAD ITEMS
        new_data['events'] = await call("get_events", {}, () => console.log("Events downloaded!"), () => console.log("Failed to download events!"))

        setData(new_data)
    }

    return (
        <DataContext.Provider value={{ data, removeItem, updateItem, changeOrder, hideCategory, updateEvent, removeEvent }}>
            {data !== null && children}
        </DataContext.Provider>
    )

}

// Function to determine the auth state
export function useData() {
    const { data, removeItem, updateItem, changeOrder, hideCategory, updateEvent, removeEvent } = useContext(DataContext);
    return { data: data, removeItem: removeItem, updateItem: updateItem, changeOrder: changeOrder, hideCategory: hideCategory, updateEvent: updateEvent, removeEvent: removeEvent };
}