import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import './App.css';
import Home from './templates/Home'
import Products from './templates/Products'
import Editor from './templates/Editor'
import Login from './templates/Login'
import Events from "./templates/Events";
import EventEditor from "./templates/EventEditor";
import { useAuth } from "./components/AuthProvider/AuthProvider";


// The private route checks if the user is currently logged and if so return the
// children otherwise redirect to the login page
export const PrivateRoute = ({ children }) => {

  // Call useAuth to know if the user is authenticated
  let { isAuthenticated } = useAuth()

  console.log("Auth -->", isAuthenticated)

  // If-else with the redirect
  return isAuthenticated === true ? children : <Navigate to="/login" />

}

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />
        <Route path="/login" element={<Login />} />
        <Route path="/events" element={<PrivateRoute><Events /></PrivateRoute>} />
        <Route path="/events/edit/:id" element={<PrivateRoute><EventEditor /></PrivateRoute>} />
        <Route path="/products/edit/:id" element={<PrivateRoute><Editor /></PrivateRoute>} />
        <Route path="/products/:id" element={<PrivateRoute><Products /></PrivateRoute>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
