import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useData } from "../components/DataProvider/DataProvider";
import Input from "../components/Input/Input";
import '../css/style.css'
import ITA from '../images/italy.png'
import ENG from '../images/united-kingdom.png'
const { v4: uuid } = require('uuid')

function initialize(x, id) {
  if (x === undefined)
    return {
      "class": id,
      "id": uuid()
    }
  return x
}


export default function Editor() {

  const params = useParams()
  const navigate = useNavigate()
  const { data, removeItem, updateItem } = useData()
  const [item, setItem] = useState(initialize(data.items[params.id], params.id))
  const [template, setTemplate] = useState(null)
  const [message, setMessage] = useState(null)

  useEffect(() => {

    var temp = item['class'] in data.templates.v1["beverage"] ? data.templates.v1["beverage"][item['class']] : data.templates.v1["food"][item['class']]
    setTemplate(temp)

    if (!("prices" in item)) {
      var arr = []
      for (let i = 0; i < temp["info"]["prices"]; i++) {
        arr.push({
          "label": temp["info"]["prices"] === 1 ? "UNICO" : "",
          "value": 0.00
        })
      }
      item["prices"] = arr
    }

    if ("prices" in item && item.prices.length !== temp["info"]["prices"]) {
      var arr = item["prices"]
      for (let i = 0; i < temp["info"]["prices"]; i++)
        if (i >= item.prices.length)
          arr.push({
            "label": "",
            "value": 0.00
          })
      item["prices"] = arr
    }

  }, [])

  async function save() {
    try {
      updateItem("items", item)
      setMessage("Saved correctly!")
    } catch (e) {
      setMessage(e)
    }
    setTimeout(() => setMessage(null), 3500)
  }

  function setValue(key, value) {
    var new_item = { ...item }
    new_item[key] = value
    setItem(new_item)
  }

  function setPrice(index, value) {
    var new_item = { ...item }
    new_item["prices"][index]['value'] = value
    setItem(new_item)
  }

  function setPriceLabel(index, label) {
    var new_item = { ...item }
    new_item["prices"][index]['label'] = label
    setItem(new_item)
  }

  function createInputItem(key, inputTemplate) {
    return <div key={key} className="row mb-3">
      <div className="col-12 col-md-6 label text-uppercase">
        <Input label={key} flag={ITA} value={item[key]} type={inputTemplate} id={key} onChange={(e) => setValue(key, e.target.value)} />
      </div>
      <div className="col-12 col-md-6 label text-uppercase">
        <Input label={key} flag={ENG} value={item[key + "_eng"]} type={inputTemplate} id={key + "_eng"} onChange={(e) => setValue(key + "_eng", e.target.value)} />
      </div>
    </div>
  }

  function createTemplate() {
    const ignore = ["name", "prices", "id", "class", "position", "is_new", "is_hidden", "class_readable"]

    return <div className="row">
      <div className="container-fluid">
        {Object.keys(template["info"]).map(key => {
          if (!(ignore.includes(key)))
            return createInputItem(key, template["info"][key])
        })}

        {/* Prices */}
        <div className="row mt-5">
          <div className="title-3 text-clem">Listino prezzi</div>
          {Array.from(Array(template["info"]["prices"]), (e, i) => {
            return <div key={i} className="col-12 col-md-6 mb-3">
              <div className="row">
                <div className="col-6">
                  <Input disabled={item["prices"].length === 1} label={"Etichetta"} flag={ITA} value={item["prices"].length === 1 ? "UNICO" : item["prices"][i]["label"]} type={"text"} id={"label"} onChange={(e) => setPriceLabel(i, e.target.value)} />
                </div>
                <div className="col-6">
                  <Input isPrice={true} label={"Prezzo"} flag={ITA} value={item["prices"][i]["value"]} type={"text"} id={"value"} onChange={(e) => setPrice(i, e.target.value)} />
                </div>
              </div>
            </div>
          })}
        </div>
      </div>
    </div>
  }

  return (
    ((template && template !== null) && <div className="container-fluid h-100 w-100 p-3 p-md-5 can-scroll">
      <div className={message != null ? "clem-message d-flex align-items-center justify-content-center" : "d-none"}>{message}</div>

      {/* ITEM NAME */}
      {item !== null && <div className="row mt-5 mb-3 title text-clem">
        <div className="col-12 text-center text-md-left">
          {(!("name" in item) || item.name.length === 0) ? "Create new " + params.id : item.name}
        </div>
      </div>}

      <div className="row justify-content-center mb-3">
        <div className="col-auto">
          <button onClick={() => navigate(-1)} >Back &#128072;&#127995;</button>
        </div>
        <div className="col-auto">
          <button onClick={() => save()} >Salva &#128190;</button>
        </div>
      </div>

      {item !== null && <div className="row mb-3">
        <div className="col-12 col-md-6">
          <Input label={"name"} flag={ITA} value={item.name} type={"text"} id={"name"} onChange={(e) => setValue("name", e.target.value)} />
        </div>
        <div className="col-12 col-md-6">
          <Input label={"name"} flag={ENG} value={item.name_eng} type={"text"} id={"name_eng"} onChange={(e) => setValue("name_eng", e.target.value)} />
        </div>
      </div>}

      {template !== null && createTemplate()}

    </div>)
  );

}