import React from "react";
import { useState } from "react";
import './style.css'

export default function Input({ isPrice = false, disabled = false, className = "", label, value, type, id, onChange, flag = null }) {

    const [v, setValue] = useState(value)
    let isImage = id.includes("image") || id.includes("locand") || id.includes("logo")

    function middleware(e) {

        // Set the internal value
        setValue(e.target.value)

        // Invoke the callback
        onChange(e)
    }

    const isUrl = string => {
        try {
            return Boolean(new URL(string));
        }
        catch (e) {
            return false;
        }
    }

    return <div className={"row flex-column custom-input " + className}>
        <div className="form-label text-uppercase">
            {label} <img className="flag" src={flag} />
        </div>
        <div className="col-12">
            <div className="input-group">
                {type !== "longtext" && <input className="form-control" name={id} type={type} id={id} value={value} onChange={middleware} required disabled={disabled} />}
                {type === "longtext" && <textarea className="form-control" rows={10} name={id} type="text" id={id} value={value} onChange={middleware} required disabled={disabled} />}
                {isPrice === true && <span className="input-group-text" id="basic-addon2">€</span>}
            </div>
        </div>
        {isImage === true && <div className="col-12">
            <div className="image mt-3 d-flex justify-content-center align-items-center">
                {(v !== null && isUrl(v)) && <img src={v} />}
                {(v === null || !isUrl(v)) && "L'immagine verrà mostrata in questo box..."}
            </div>
        </div>}
    </div>
}