import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { AuthContextProvider } from './components/AuthProvider/AuthProvider';
import { DataContextProvider } from './components/DataProvider/DataProvider';
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

const firebaseApp = initializeApp({
  apiKey: "AIzaSyCdEC4XKID4mQ5SpiGXQlbhKfXpC2CS_w0",
  authDomain: "bright-calculus-276317.firebaseapp.com",
  projectId: "bright-calculus-276317",
  storageBucket: "bright-calculus-276317.appspot.com",
  messagingSenderId: "707805024062",
  appId: "1:707805024062:web:4cb8260d4cafc32bf8e66f"
});

const auth = getAuth(firebaseApp);

signInWithEmailAndPassword(auth, "website@clempub.it", "cola9999")
  .then((userCredential) => {
    render()
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;

    console.error(errorCode, errorMessage)
  });


function render() {
  ReactDOM.render(
    <React.StrictMode>
      <AuthContextProvider>
        <DataContextProvider>
          <App />
        </DataContextProvider>
      </AuthContextProvider>
    </React.StrictMode>,
    document.getElementById('root')
  )
}