import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import '../css/style.css'
import { getFirestore, collection, getDocs, query, getDoc, where, doc, setDoc, deleteDoc } from "firebase/firestore";
import { useData } from "../components/DataProvider/DataProvider";

export default function Events(props) {

    const db = getFirestore()
    const navigate = useNavigate()
    const { data, removeItem, updateItem, changeOrder, hideCategory, updateEvent, removeEvent } = useData()

    const [items, setItems] = useState([])
    const [image, setImage] = useState(null)

    useEffect(async () => {
        if (items.length == 0)
            downloadItems()
    })

    const sortItems = (list) => {
        const result = Array.from(list);
        result.sort((a, b) => a.date > b.date ? -1 : 1);

        return result;
    };

    async function downloadItems() {
        let data = []
        const querySnapshot = await getDocs(query(collection(db, "events")))
        querySnapshot.forEach((doc) => {
            data.push(doc.data())
        });

        setItems(sortItems(data))
    }

    async function toggleMenuVisibility(item) {
        item['highlight'] = !item['highlight']
        await setDoc(doc(db, "events", item.id), item)
        downloadItems()
    }

    return (<>

        {image !== null && <div onClick={() => setImage(null)} className="popup d-flex align-items-center justify-content-center"><img src={image} /></div>}

        <div className="container-fluid background-clem-dark h-100 w-100 p-5">
            <div className="row my-5 title text-clem">
                <div className="col-12">Eventi programmati</div>
            </div>
            <div className="row mb-5">
                <div className="col-4">
                    <button onClick={() => navigate('/events/edit/new')} className="w-100 btn btn-warning">Crea un nuovo evento</button>
                </div>
            </div>

            <div className="row">
                {items.map((item, index) => <div className="col-12">
                    <div className="row item-card">
                        <div className="col-4">
                            <img onClick={() => setImage(item.logo)} className="w-100 on-hover" src={item.logo} />
                        </div>
                        <div className="col-8">
                            <div className="row">

                                {/* NOME */}
                                <div className="col-12 title text-clem">
                                    {item.name}
                                </div>

                                {/* DATA */}
                                <div className="col-12 flex-column d-flex mb-3">
                                    <div className="label">
                                        Data programmazione
                                    </div>
                                    <div className="content-bold">{item.data}</div>
                                </div>

                                {/* DATA */}
                                <div className="col-12 flex-column d-flex mb-3">
                                    <div className="label">
                                        In evidenza
                                    </div>
                                    <div className="content-bold">{item.highlight === true ? 'Sì' : 'No'}</div>
                                </div>

                                {/* DATA */}
                                <div className="col-12 flex-column d-flex mb-3">
                                    <div className="label">
                                        Descrizione
                                    </div>
                                    <div className="content-bold">{item.descrizione}</div>
                                </div>

                                {/* ACTION BUTTON */}
                                <div className="col-12 mb-3">
                                    <button onClick={() => removeEvent(item.id)} className="btn btn-outline-success">ELIMINA</button>
                                    <button onClick={() => navigate('/events/edit/' + item.id)} className="btn btn-outline-success">MODIFICA</button>
                                    <button onClick={() => toggleMenuVisibility(item)} className="btn btn-outline-success">{item.highlight === false ? "EVIDENZIA" : "NON EVIDENZIARE"}</button>
                                    {/* <button onClick={() => toggleNewFlag(item)} className="btn btn-outline-success">MOSTRA COME NOVITA'</button> */}
                                    {/* <button onClick={() => toggleVisibility(item.id)} className="btn btn-outline-success">{!(item.id in showGrid) || (item.id in showGrid && showGrid[item.id] == false) ? "MOSTRA INFORMAZIONI" : "NASCONDI INFORMAZIONI"}</button> */}
                                </div>

                                {/* MORE INFO */}
                                {/* {currentTemplate != null && Object.keys(currentTemplate["info"]).map((key, index) =>
                                    !(ignore.includes(key)) && (
                                        <div key={key} id={item.id} className={!(item.id in showGrid) || (item.id in showGrid && showGrid[item.id] == false) ? 'col-12 mt-2 d-none' : 'col-12 mt-2 d-block'}>
                                            <div className="row">
                                                <div className="col-6 label">{key + " (ITA)"}</div>
                                                <div className="col-6 label">{key + " (ENG)"}</div>
                                                <div className="col-6">{item[key]}</div>
                                                <div className="col-6">{item[key + '_eng']}</div>
                                            </div>
                                        </div>
                                    )
                                )} */}

                            </div>
                        </div>
                    </div>
                </div>
                )}
            </div>

        </div>

    </>);

}