import React from "react";
import '../css/style.css'
import { useData } from "../components/DataProvider/DataProvider";
import { useNavigate } from "react-router";

export default function Home() {

    const navigate = useNavigate()
    const { data, removeItem, updateItem, changeOrder, hideCategory, updateEvent, removeEvent } = useData()

    async function toggleMenuVisibility(item) {
        item['highlight'] = !item['highlight']
        updateItem("events", item)
    }

    function getCount(id) {
        let counter = 0
        Object.values(data.items).map((item, ix) => {
            if (item.class === id)
                counter++
        })
        return counter
    }

    const eventItems = Object.keys(data.events).map((item, i) => <div key={i} className="col-12 col-md-6">
        <div className="item-card p-3">
            <div className="w-100 title text-center">
                {data.events[item].name}
            </div>

            <div className="w-100 text-center">
                <img style={{ maxHeight: 200 }} src={data.events[item].logo} className="img-fluid mt-2" />
            </div>
            <div className="row mt-2 justify-content-md-center">
                <button className="col-12 col-md-auto mt-2 mx-1" onClick={() => navigate('/events/edit/' + data.events[item].id)}>Edit &#9997;</button>
                <button className="col-12 col-md-auto mt-2 mx-1" onClick={() => removeEvent(data.events[item].id)}>Remove &#10060;</button>
            </div>
        </div>
    </div>)

    const listItems = Object.keys(data.templates.v1).map((group, index) =>
        <div className="row" key={index}>
            <div className="col-12 text-clem title-2 mt-5 text-center">{group}</div>

            {Object.keys(data.templates.v1[group]).map((id, key) =>
                <div key={key} className="col-12 col-md-4 mb-3">
                    <div className="item-card p-3">
                        <div className="text-clem title-3 w-100 text-center">{id}</div>
                        <div className="col-12 text-center">{data.templates.v1[group][id].hidden === false ? "VISIBILE" : "NASCOSTA"}</div>
                        <div className="w-100 title-2 text-clem text-center mt-3">{getCount(id)}</div>
                        <div className="w-100 d-flex flex-row align-items-center justify-content-center mt-3">
                            <button className="mx-1" onClick={() => navigate('/products/' + id)}>Apri &#9997;</button>
                            <button className="mx-1" onClick={() => hideCategory(group, id)}>{data.templates.v1[group][id].hidden === false ? "Nacondi" : "Mostra"} &#9997;</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );

    return (

        <div className="container-fluid h-100 w-100 p-3 p-md-5 can-scroll">

            <div className="row">
                <div className="col-12 mt-3 mb-3 title text-clem text-center">
                    CONTROL CENTER<br />&#128512;
                </div>
            </div>

            <div className="row">

                {/* Events block */}
                <div className="col-12 text-clem title-2 text-center">
                    Events
                </div>

                <div className="col-12 mt-1 mb-3 text-center">
                    <button onClick={() => navigate('/events/edit/new')}>Create new event</button>
                </div>

                {eventItems}

            </div>

            {/* All other items */}
            {listItems}

        </div>
    )

}