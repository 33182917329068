import axios from "axios"

async function make_call(operation, payload = {}, onSuccess = null, onFail = null) {

  
  let endpoint = process.env.REACT_APP_API_SERVER === undefined ? 'http://127.0.0.1:3001/' : process.env.REACT_APP_API_SERVER
  if (endpoint.slice(-1) !== "/")
    endpoint += "/"

  try {
    let data = await axios.post(endpoint + operation + '?ts=' + Math.floor(Math.random() * 1000000), 
      payload, {
      headers: {
        "Content-type": "application/json"
      },
    })

    // Print in the console the exception
    if (data.status !== 200) {
      console.error("Error", payload)
      console.error(data.exception)
      console.error('------')
    }

    // If the call success and onSuccess object is different from null than execute the operation specified by the user
    if (data.status === 200 && onSuccess != null)
      onSuccess(data.payload)

    // If the call fails and onFail object is different from null than execute the operation specified by the user
    if (data.status !== 200 && onFail != null)
      onFail()

    return data.status === 200 ? data.data : null

  } catch (e) {
    if (e !== null && e.response !== undefined && e.response !== null && Object.keys(e.response).includes("status") && e.response.status === 403) {
      localStorage.removeItem("adq-username")
      localStorage.removeItem("adq-token")
    }
    console.error(e)
    return "KO"
  }
}

export async function call(api, payload, onSuccess, onFail) {

  // If the payload is still null return KO because the function is
  // not yet enabled!
  if (payload == null)
    return "KO"

  // Perform the call
  let data = await make_call(api, payload, onSuccess, onFail)

  // Return the result payload
  return data

}

// GET ALL PROJECTS
export async function get_projects(payload) {

  return await make_call("get_projects", payload)

}

// GET ALL USERS
export async function get_all_users() {

  return await make_call("get_all_users")

}

// GET ALL USERS
export async function get_all_projects_id() {

  return await make_call("get_all_projects_id")

}

// GET ALL TABLES
export async function get_all_tables() {

  return await make_call("get_all_tables")

}

// GET ALL TABLES OF A SPECIFIC PROJECT
export async function get_tables_by_project() {

  return await make_call("get_tables_by_project")

}

// GET ALL CONNECTORS RELATED TO THE PROJECT
export async function get_connectors() {

  return await make_call("get_connectors")

}

// GET ALL JOBS
export async function get_jobs(payload) {

  return await make_call("get_jobs", payload)

}

// GET CONFIGURATIONS
export async function get_config(payload) {

  return await make_call("get_config", payload)

}