import React from "react";
import './style.css'

export default function Price({ prices }) {

    const card = parseInt(12 / prices.length)

    const converter = {
        "-2": "piadina",
        "-3": "panino",
        "-1": "unico",
        "0": "unico"
    }

    return <div className="row">
        <div className="col-12 label mb-2">
            Listino prezzi
        </div>
        {prices !== null && prices.map((price, ix) => <div key={ix} className={"d-flex flex-column col-auto"}>
            <div className="price-box-label text-center">
                {converter[price.label]}
            </div>
            <div className="price-box px-2 py-1">
                € {parseFloat(price.value).toFixed(2)}
            </div>
        </div>)}
    </div>

}